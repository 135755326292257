* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.app{
  width: clamp(324px,100%,550px)
}

.MuiDialog-paper{
  width: clamp(324px,100%,550px) !important;
}